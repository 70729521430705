import React, { useContext, useEffect, useState } from "react";
import { useRequest } from "../hooks";
import DataContext from "./DataContext";
import {
  ClientProps,
  CustomerProps,
  FilterProps,
  InvoiceProps,
  ListComponentProps,
  QuickbooksCompanyProps,
  StatusProps,
  TemplateProps,
  UserAccountProps,
} from "../interfaces/interfaces";
import {
  ALERT_INITIAL_STATE,
  ERROR_ENCRYPTED,
  STATUS_INITIAL_LIST,
} from "../utils/data";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { Dayjs } from "dayjs";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const LIST_INITIAL_STATE = [{ label: "", value: "" }];

const DataProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const { onLogout, changeProfileInfo, profileInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ status: false, message: "" });
  const [isSuccess, setIsSuccess] = useState({ status: false, message: "" });
  const [statusList, setStatusList] =
    useState<StatusProps[]>(STATUS_INITIAL_LIST);
  const [isModalStatusOpen, SetIsModalStatusOpen] = useState(false);
  const [statusToEdit, setStatusToEdit] = useState<StatusProps | null>(null);
  const [invoiceList, setInvoiceList] = useState<InvoiceProps[]>([]);
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [customerByClient, setCustomerByClient] = useState<CustomerProps[]>([]);
  const [isWarning, setIsWarning] = useState<{
    status: boolean;
    message: string;
    actionLabel?: string;
    action?: () => void;
  }>({ status: false, message: "" });
  const [customerOptions, setCustomerOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [statusOptions, setStatusOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [client, setClient] = useState<null | ClientProps>(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [userAccount, setUserAccount] = useState<UserAccountProps[]>([]);
  const [termOptions, setTermOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [templateList, setTemplateList] = useState<TemplateProps[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isError, isSuccess]);

  useEffect(() => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
  }, []);

  useEffect(() => {
    let optionsCustomer: ListComponentProps[] = [];
    customerList.forEach((customer) => {
      optionsCustomer.push({
        label:
          customer.DisplayName && customer.FullyQualifiedName
            ? customer.DisplayName + " (" + customer.FullyQualifiedName + ")"
            : customer.CompanyName,
        value: customer.Id,
      });
    });
    optionsCustomer.length > 0 && setCustomerOptions(optionsCustomer);
  }, [customerList]);

  useEffect(() => {
    let options: ListComponentProps[] = [];
    statusList.forEach((status) => {
      options.push({
        label: status.Label,
        value: status._id || status.Label,
      });
    });
    options.length > 0 && setStatusOptions(options);
  }, [statusList]);

  const handleTour = (value: boolean) => {
    setIsTourOpen(value);
  };

  const handleWarning = (
    status: boolean,
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => {
    setIsWarning({ status, message, actionLabel, action });
  };

  const handleSuccess = (status: boolean, message: string) => {
    setIsSuccess({ status, message });
  };

  const formatTermList = (list: { Name: string; Id: string }[]) => {
    let options: ListComponentProps[] = [];
    list.forEach((status) => {
      options.push({
        label: status.Name,
        value: status.Id,
      });
    });
    options.length > 0 && setTermOptions(options);
  };

  const getData = (
    endpoint:
      | "status"
      | "customer"
      | "invoice"
      | "deposit"
      | "credit"
      | "payment"
      | "journal"
      | "client"
      | "user-account"
      | "profile-info"
      | "term"
      | "template",
    idClient: string,
    search?: string,
    filters?: any
  ) => {
    setIsError(ALERT_INITIAL_STATE);
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && `search=${encodeURIComponent(search)}`;
    let filtersEncoded = filters && encodeURIComponent(JSON.stringify(filters));
    if (filtersEncoded) {
      searchEncoded
        ? (searchEncoded += `&${filtersEncoded}`)
        : (searchEncoded = `filters=${filtersEncoded}`);
    }
    handleRequest({
      endpoint: searchEncoded
        ? `${endpoint}/${idClient}?${searchEncoded}`
        : `${endpoint}/${idClient}`,
      options,
      onSuccess: (response) => {
        switch (endpoint) {
          case "status":
            setStatusList(response.data);
            break;
          case "customer":
            setCustomerList(response.data);
            break;
          case "client":
            setClient(response.data);
            break;
          case "term":
            formatTermList(response.data);
            break;
          case "profile-info":
            changeProfileInfo(response.data);
            break;
          case "template":
            setTemplateList(response.data);
            break;
          default:
            setInvoiceList(response.data);
            break;
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const getCustomersListByClient = ({
    idClient,
    filters,
    search,
    next,
  }: {
    idClient: string;
    filters?: { customer: string[] | null };
    search?: string | null;
    next?: () => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    let apiEndpoint = searchEncoded
      ? `customer/${idClient}?search=${searchEncoded}`
      : `customer/${idClient}`;
    if (filters) {
      if (searchEncoded) {
        apiEndpoint = `${apiEndpoint}&filters=${JSON.stringify(filters)}`;
      } else {
        apiEndpoint = `${apiEndpoint}?filters=${JSON.stringify(filters)}`;
      }
    }
    handleRequest({
      endpoint: apiEndpoint,
      options,
      onSuccess: (response) => {
        setCustomerByClient(response.data);
        next && next();
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const fetchUserAccounts = (idUser: string) => {
    try {
      setIsLoading(true);
      setIsError(ALERT_INITIAL_STATE);
      handleRequest({
        endpoint: `user-account/${idUser}`,
        onSuccess: (response) => {
          if (response.data) {
            setUserAccount(response.data);
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          console.log(e);
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
          if (e.message === "Not Authorized")
            onLogout(() => navigate(`/login?message=${ERROR_ENCRYPTED}`));
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const getQbCompanyInfo = (
    realmId: string,
    next: (data: QuickbooksCompanyProps) => void
  ) => {
    try {
      setIsLoading(true);
      return handleRequest({
        endpoint: `qbcompany/${realmId}`,
        onSuccess: (response) => {
          if (response.data) {
            next(response.data);
          } else {
            return null;
          }
        },
        onError: (e) => {
          return null;
        },
      });
    } catch (e) {
      return null;
    }
  };

  const getReportData = (
    endpoint: "report" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/?filters=${JSON.stringify(filters)}`,
      options,
      onSuccess: (response) => {
        if (response.data.length > 0) {
          setIsSuccess({ status: true, message: "Data fetched successfully" });
          next && next(response.data);
        } else {
          setIsError({ status: true, message: "No data" });
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleCreateData = (
    endpoint: "status" | "template",
    idClient: string,
    data: any,
    next?: () => void
  ) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest({
      endpoint: endpoint + "/" + idClient,
      options,
      onSuccess: () => {
        getData(endpoint, idClient);
        next && next();
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleEditData = ({
    endpoint,
    newData,
    next,
    nextOnFailed,
  }: {
    endpoint: string;
    newData: any;
    next?: () => void;
    nextOnFailed?: (e: any) => void;
  }) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ ...newData }),
    };
    handleRequest({
      endpoint,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
        next && next();
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
        nextOnFailed && nextOnFailed(e);
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleDeleteData = (
    endpoint:
      | "status"
      | "invoice"
      | "payment"
      | "deposit"
      | "credit"
      | "journal",
    idClient: string,
    id: string
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "DELETE",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/${id}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Item Deleted" });
        getData(endpoint, idClient);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleBulkEdit = ({
    endpoint,
    items,
    newData,
    next,
  }: {
    endpoint: string;
    items: string[];
    newData: any;
    next?: () => void;
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ idList: items, ...newData }),
    };
    handleRequest({
      endpoint,
      options,
      onSuccess: () => {
        next && next();
        setIsSuccess({ status: true, message: "Updated" });
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleBulkDeleteData = (
    endpoint:
      | "status"
      | "invoice"
      | "payment"
      | "deposit"
      | "credit"
      | "journal",
    idClient: string,
    idList: string[]
  ) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "DELETE",
      body: JSON.stringify({ idList }),
    };
    handleRequest({
      endpoint: `${endpoint}-bulk/${idClient}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Items Deleted" });
        getData(endpoint, idClient);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
        if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  return (
    <DataContext.Provider
      value={{
        dataLoading: isLoading,
        dataSuccess: isSuccess,
        dataError: isError,
        dataWarning: isWarning,
        statusList,
        invoiceList,
        customerOptions,
        statusOptions,
        isModalStatusOpen,
        statusToEdit,
        client,
        isTourOpen,
        userAccount,
        termOptions,
        customerByClient,
        templateList,
        handleTour,
        openCloseModalStatus: (value: boolean) => SetIsModalStatusOpen(value),
        handleWarning,
        handleSuccess,
        selectStatusToEdit: (value: StatusProps | null) =>
          setStatusToEdit(value),
        getData,
        getReportData,
        handleCreateData,
        handleEditData,
        handleBulkEdit,
        handleDeleteData,
        handleBulkDeleteData,
        fetchUserAccounts,
        getQbCompanyInfo,
        getCustomersListByClient,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
